.sign
  display: flex
  align-items: center
  justify-content: center
  overflow: hidden
  background: #000

  .auth-block
    width: 500px
    height: auto
    backdrop-filter: blur(12px)
    background: linear-gradient(157.87deg, rgb(25 25 25 / 50%), rgb(16 16 16 / 50%))
    display: flex
    align-items: center
    justify-content: center
    position: absolute
    border-radius: 30px
    padding: 50px
    z-index: 2
    max-width: 500px
    box-sizing: border-box
    border: none
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2)

    &::after
      background: linear-gradient(135deg, #ffffff0d 15%, rgb(255 255 255 / 4%) 35%, rgba(16, 16, 16, 0) 100%)
      border-radius: 30px
      content: ""
      inset: 0
      -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)
      -webkit-mask-composite: xor
      mask-composite: exclude
      padding: 2px
      position: absolute
      width: calc(100% - 4px)
      z-index: -1

    .sign-block
      .sign-logo
        display: flex
        align-items: center
        flex-direction: column
        gap: 16px

        span
          color: #8E8E8E

        a
          color: #fff
          text-decoration: none
          cursor: pointer
          transition: all .2s ease-out
          opacity: 1

          &:hover
            color: #fff
            text-decoration: none
            opacity: .8

          &:active
            color: #fff
            text-decoration: none
            opacity: .5

      .sign-form
        margin-top: 50px

        .sign-form-title
          h1
            text-align: center
            margin-bottom: 30px
            color: #fff

        form
          min-width: 416px
          display: flex
          flex-direction: column
          gap: 18px

          .label-blind
            display: flex
            gap: 10px

          label
            position: relative

            input
              width: 100%
              box-sizing: border-box

              &.error
                background: rgba(255, 49, 49, 0.10)
                border: 1px solid #FF3131

            p
              margin-top: 8px

              &.error
                color: #FF3131

              &.verity
                color: #3FA601

          button
            margin-top: 16px
            width: 100%

          .password-toggle
            cursor: pointer
            background: none
            border: none
            position: absolute
            right: 0
            top: 0
            margin: 0
            padding: 15px
            opacity: 0.5
            transition: all .2s ease-out

            &:hover
              opacity: 1

            &:active
              opacity: 0.8

        .sign-form-link
          margin-top: 24px
          display: flex
          align-items: center
          //flex-direction: column
          gap: 10px

          a, button
            width: 100%
            box-sizing: border-box


      .sign-soc
        margin-top: 30px
        display: flex
        gap: 30px
        align-items: center
        justify-content: center
        flex-direction: column

        .separator-soc
          display: flex
          gap: 24px
          align-items: center
          justify-content: center
          width: 100%
          box-sizing: border-box
          opacity: 0.5

          span
            color: #ADADAD

          &:before
            content: ''
            width: 150px
            height: 1px
            background: #ADADAD
            position: relative
            display: block

          &:after
            content: ''
            width: 150px
            height: 1px
            background: #ADADAD
            position: relative
            display: block

        .sign-soc-btn
          display: flex
          align-items: center
          justify-content: space-between
          flex-direction: column
          gap: 12px
          width: 100%

          .button
            width: 100%
            gap: 12px

  .bg-block
    width: 100%
    height: 100vh
    background: #000
    display: flex
    align-items: center
    justify-content: center
    opacity: 1

    .bg-active
      background: #000
      width: 100vw !important
      height: 100vh !important

      canvas
        width: 100vw !important
        height: 100vh !important
        background: #000

  .info-form
    padding: 18px
    border: 1px solid #4E4E4E
    border-radius: 10px
    text-align: center
    background: #262626
    transition: all .2s ease-out

    &.err
      margin-top: 18px

    &.acc
      margin-top: 18px

    p
      color: #8E8E8E

  .logo-title
    text-align: center