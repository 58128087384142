.authSetting
  display: flex
  position: relative
  flex-direction: column
  gap: 24px
  width: 100%
  box-sizing: border-box

  .authSettingGroup
    display: flex
    position: relative
    flex-direction: column
    gap: 24px
    width: 100%
    box-sizing: border-box

  .authSettingItem
    background: #101010
    padding: 24px
    width: 100%
    border-radius: 10px
    box-sizing: border-box
    position: relative

    .buttonBlock
      width: 100%
      display: flex
      padding-top: 24px
      margin-top: 20px
      border-top: 1px solid rgba(255, 255, 255, 0.05)
      justify-content: end

      .button
        border-radius: 8px
        background: #3968FF
        color: #fff
        padding: 14px
        min-width: 200px
        text-align: center
        font-size: 14px
        font-style: normal
        font-weight: 700
        line-height: 20px
        cursor: pointer
        transition: all 0.2s ease-out

        &:hover
          background: #1B49DC

    .authFormItemGroup
      display: flex
      flex-direction: column
      gap: 16px

      .authFormItem
        display: flex
        gap: 20px
        border-bottom: 1px solid rgba(255, 255, 255, 0.05)
        padding-bottom: 16px

        &:last-child
          padding-bottom: 0
          border-bottom: none

        .authFormItemTitle
          display: flex
          gap: 6px
          flex-direction: column
          width: 100%
          font-size: 14px
          font-style: normal
          font-weight: 400
          line-height: 20px

        .itemInfoGroup
          display: flex
          gap: 30px
          flex-shrink: 0
          opacity: 0.5

          .itemInfo
            font-size: 12px
            font-style: normal
            font-weight: 400
            line-height: 16px
            flex-shrink: 0
            width: 90px
            text-align: right

          .itemInfoId
            font-size: 12px
            font-style: normal
            font-weight: 400
            line-height: 16px
            flex-shrink: 0
            width: 250px
            text-align: right

        .authFormItemInput
          display: flex
          width: 100%
          gap: 24px
          position: relative

      .buttonDeleteAuth
        cursor: pointer
        opacity: 1
        transition: all 0.2s ease-out
        display: flex

        &:hover
          opacity: 0.8

    .info
      color: #8E8E8E
      font-weight: 400
      font-size: 14px
      line-height: 24px

    .authSettingName
      width: 100%
      padding-bottom: 24px
      border-bottom: 1px solid rgba(255, 255, 255, 0.05)
      margin-bottom: 16px

      h2, h3
        font-size: 18px
        font-style: normal
        font-weight: 600
        line-height: normal

//Иконки
i
  &.iconClose
    content: ''
    border: none
    display: inline-block
    flex-grow: 0
    flex-shrink: 0
    height: 20px
    width: 20px
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'%3E%3Cpath stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='m8 8 8 8m0-8-8 8'/%3E%3C/svg%3E%0A")
    background-position: 50%
    background-repeat: no-repeat
    background-size: 100%
    transition: all 0.2s ease-out

@media screen and (max-width: 1200px)
  .authSetting
    .authSettingItem
      .authFormItemGroup
        .authFormItem
          .itemInfoGroup
            gap: 10px
            flex-wrap: wrap
            align-items: flex-start

            .itemInfo
              width: auto

            .itemInfoId
              width: auto

          .authFormItemInput
            flex-direction: column
            gap: 16px

            .buttonDeleteAuth
              position: absolute
              top: 0
              right: 0