.platformSetting
  position: relative
  display: block

  .containerPlatformSetting
    position: relative
    max-width: 1052px
    margin: 0 auto
    padding-bottom: 126px

    .settingBlock
      display: flex
      flex-direction: column

      .platformSetting
        display: flex
        position: relative
        flex-direction: column
        gap: 24px
        width: 100%
        box-sizing: border-box

        .platformSettingGroup
          display: flex
          position: relative
          flex-direction: column
          gap: 24px
          width: 100%
          box-sizing: border-box

        .platformSettingItem
          background: #101010
          padding: 24px
          width: 100%
          border-radius: 10px
          box-sizing: border-box
          position: relative

          .buttonBlock
            width: 100%
            display: flex
            padding-top: 24px
            margin-top: 20px
            border-top: 1px solid rgba(255, 255, 255, 0.05)
            justify-content: end

            .button
              border-radius: 8px
              background: #3968FF
              color: #fff
              padding: 14px
              min-width: 200px
              text-align: center
              font-size: 14px
              font-style: normal
              font-weight: 700
              line-height: 20px
              cursor: pointer
              transition: all 0.2s ease-out

              &:hover
                background: #1B49DC

          .platformFormItemGroup
            display: flex
            flex-direction: column
            gap: 20px

            .platformFormItem
              display: flex
              gap: 20px
              border-bottom: 1px solid rgba(255, 255, 255, 0.05)
              padding-bottom: 20px

              &:last-child
                padding-bottom: 0
                border-bottom: none

              .platformFormItemTitle
                display: flex
                gap: 6px
                flex-direction: column
                width: 50%

                p
                  font-size: 12px
                  font-style: normal
                  font-weight: 400
                  line-height: 18px
                  color: rgba(255, 255, 255, 0.25)

              .platformFormItemInput
                display: flex
                width: 50%
                gap: 14px
                flex-direction: column
                position: relative

                .platformFormItemInputPass
                  position: absolute
                  top: 10px
                  right: 16px
                  cursor: pointer
                  opacity: 0.5
                  transition: all 0.2s ease-out

                  &:hover
                    opacity: 1

          .platformSettingName
            width: 100%
            padding-bottom: 24px
            border-bottom: 1px solid rgba(255, 255, 255, 0.05)
            margin-bottom: 24px

            h2, h3
              font-size: 18px
              font-style: normal
              font-weight: 600
              line-height: normal

          .platformSettingAccount
            display: flex
            align-items: center
            justify-content: space-between
            position: relative

            .platformAvatarBlock
              display: flex
              gap: 20px

              img
                border-radius: 60px

            .platformAvatarUpload
              display: flex
              gap: 10px
              flex-direction: column

              .platformAvatarButton
                display: flex
                gap: 10px

                .buttonUpload
                  border-radius: 6px
                  background: #3968FF
                  color: #fff
                  font-size: 12px
                  font-style: normal
                  font-weight: 700
                  line-height: 20px
                  padding: 6px 12px
                  transition: all 0.2s ease-out

                  &:hover
                    background: #1B49DC

                .buttonRemove
                  border-radius: 6px
                  background: rgba(255, 255, 255, 0.05)
                  color: #fff
                  font-size: 12px
                  font-style: normal
                  font-weight: 700
                  line-height: 20px
                  padding: 6px 12px
                  transition: all 0.2s ease-out

                  &:hover
                    opacity: 0.8

              .info
                font-size: 12px
                font-style: normal
                font-weight: 400
                line-height: 18px
                color: rgba(255, 255, 255, 0.25)

            .buttonDeleteUser
              border-radius: 8px
              background: #1D1D1D
              padding: 14px
              min-width: 240px
              text-align: center
              font-size: 14px
              font-style: normal
              font-weight: 700
              line-height: 20px
              cursor: pointer
              color: #fff
              transition: all 0.2s ease-out

              &:hover
                opacity: 0.8

//Иконки
i
  &.iconEye
    &::before
      content: ""
      border: none
      display: inline-block
      flex-grow: 0
      flex-shrink: 0
      height: 24px
      width: 24px
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'%3E%3Cpath fill='%23fff' d='M19.507 10.226c-1.034-1.684-3.385-4.51-7.512-4.51-4.126 0-6.477 2.826-7.51 4.51a3.273 3.273 0 0 0 0 3.441c1.033 1.684 3.384 4.51 7.51 4.51 4.127 0 6.478-2.825 7.512-4.51a3.273 3.273 0 0 0 0-3.44ZM18.37 12.97c-.887 1.445-2.897 3.874-6.375 3.874s-5.487-2.43-6.374-3.873a1.946 1.946 0 0 1 0-2.046C6.508 9.48 8.517 7.05 11.995 7.05c3.479 0 5.488 2.427 6.376 3.874a1.946 1.946 0 0 1 0 2.046Z'/%3E%3Cpath fill='%23fff' d='M11.995 8.613a3.331 3.331 0 0 0-3.268 3.984 3.333 3.333 0 0 0 6.039 1.202 3.334 3.334 0 0 0-2.77-5.186Zm0 5.334a2 2 0 1 1 0-3.999 2 2 0 0 1 0 3.999Z'/%3E%3C/svg%3E")
      background-position: 50%
      background-repeat: no-repeat
      background-size: 24px
      transition: all .2s ease-out

  &.iconEyeClose
    &::before
      content: ""
      border: none
      display: inline-block
      flex-grow: 0
      flex-shrink: 0
      height: 24px
      width: 24px
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'%3E%3Cpath fill='%23fff' d='M19.511 10.306A10.576 10.576 0 0 0 17.265 7.7l1.866-1.867a.666.666 0 0 0-.943-.943L16.16 6.923A8.03 8.03 0 0 0 12 5.796c-4.126 0-6.477 2.826-7.511 4.51a3.273 3.273 0 0 0 0 3.44c.601.986 1.36 1.867 2.246 2.607L4.87 18.22a.667.667 0 1 0 .943.942l2.034-2.034A8.031 8.031 0 0 0 12 18.256c4.126 0 6.477-2.825 7.511-4.51a3.273 3.273 0 0 0 0-3.44ZM5.624 13.049a1.946 1.946 0 0 1 0-2.045C6.514 9.56 8.523 7.13 12 7.13a6.73 6.73 0 0 1 3.176.776l-1.341 1.342a3.326 3.326 0 0 0-4.612 4.614l-1.54 1.54a9.147 9.147 0 0 1-2.059-2.353ZM14 12.026a2 2 0 0 1-1.999 2 1.967 1.967 0 0 1-.856-.2L13.8 11.17c.13.266.2.56.2.856Zm-3.998 0a2 2 0 0 1 1.999-2c.297.001.59.07.856.2l-2.655 2.657a1.967 1.967 0 0 1-.2-.857Zm8.375 1.023c-.89 1.444-2.899 3.874-6.376 3.874a6.729 6.729 0 0 1-3.176-.777l1.341-1.342a3.326 3.326 0 0 0 4.612-4.613l1.54-1.54a9.147 9.147 0 0 1 2.059 2.353 1.945 1.945 0 0 1 0 2.045Z'/%3E%3C/svg%3E")
      background-position: 50%
      background-repeat: no-repeat
      background-size: 24px
      transition: all .2s ease-out

//Элементы формы
.checkboxWrapper
  display: flex
  align-items: flex-start
  justify-content: center
  gap: 12px
  width: auto
  height: auto
  cursor: pointer
  position: relative

  input[type="checkbox"]
    display: none

  input[type="checkbox"]:checked
    & + .checkboxSlider
      background-color: #3968FF
      opacity: 1

      .checkboxKnob
        &:before
          opacity: 1

  .checkboxSlider
    background-color: #080808
    position: relative
    border-radius: 100px
    top: 0
    left: 0
    width: 36px
    height: 20px
    opacity: 1
    transition: all .2s ease-out

  .checkboxKnob
    position: absolute
    transition: all .2s ease-out

  input[type="checkbox"]:checked
    & + .checkboxSlider
      .checkboxKnob
        background-color: #fff
        left: calc(100% - 16px - 2px)

  .checkboxKnob
    width: calc(24px - 8px)
    height: calc(24px - 8px)
    border-radius: 50%
    left: 2px
    top: 2px
    background-color: #fff
    background-repeat: no-repeat
    background-size: 80%
    background-position: center
    position: relative

  input[type="checkbox"]:checked
    & ~ span
      color: #fff

  span
    color: #8E8E8E
    transition: all .2s ease-out

  &:hover

    .checkboxSlider
      opacity: 1

    span
      color: #fff

.settingInput
  border: none
  border-radius: 8px
  background: #080808
  padding: 10px 24px
  font-weight: 400
  font-size: 14px
  color: #8E8E8E
  opacity: 1
  line-height: 24px
  transition: all 0.2s ease-out
  margin: 0

  &:-webkit-autofill
    &::first-line
      font-family: "RF Dewi" !important
      font-weight: 400
      font-size: 15px

  &::-webkit-input-placeholder
    font-weight: 400
    font-size: 14px
    color: #8E8E8E
    line-height: 24px
    background: 0
    transition: all 0.2s ease-out

  &::-moz-placeholder
    font-weight: 400
    font-size: 14px
    color: #8E8E8E
    line-height: 24px
    background: 0
    transition: all 0.2s ease-out

  &:-ms-input-placeholder
    font-weight: 400
    font-size: 14px
    color: #8E8E8E
    line-height: 24px
    background: 0
    transition: all 0.2s ease-out

  &::-ms-input-placeholder
    font-weight: 400
    font-size: 14px
    color: #8E8E8E
    line-height: 24px
    background: 0
    transition: all 0.2s ease-out

  &::placeholder
    font-weight: 400
    font-size: 14px
    color: #8E8E8E
    line-height: 24px
    background: 0
    transition: all 0.2s ease-out

  &:focus
    //border: 1px solid #8E8E8E
    border: none
    color: #fff
    outline: 0 !important
    opacity: 1

    &::-webkit-input-placeholder
      color: #4E4E4E

    &::-moz-placeholder
      color: #4E4E4E

    &:-ms-input-placeholder
      color: #4E4E4E

    &::-ms-input-placeholder
      color: #4E4E4E

    &::placeholder
      color: #4E4E4E

  &:hover
    opacity: 1
    background: #080808

  &:disabled
    opacity: .5 !important

  &.gray
    border-radius: 30px
    padding: 15px 24px
    font-size: 16px

    &:-webkit-autofill
      &::first-line
        font-family: "RF Dewi" !important
        font-weight: 400
        font-size: 16px

    &::-webkit-input-placeholder
      font-weight: 400
      font-size: 16px
      color: #8E8E8E
      line-height: 24px
      background: 0
      transition: all 0.2s ease-out

    &::-moz-placeholder
      font-weight: 400
      font-size: 16px
      color: #8E8E8E
      line-height: 24px
      background: 0
      transition: all 0.2s ease-out

    &:-ms-input-placeholder
      font-weight: 400
      font-size: 16px
      color: #8E8E8E
      line-height: 24px
      background: 0
      transition: all 0.2s ease-out

    &::-ms-input-placeholder
      font-weight: 400
      font-size: 16px
      color: #8E8E8E
      line-height: 24px
      background: 0
      transition: all 0.2s ease-out

    &::placeholder
      font-weight: 400
      font-size: 16px
      color: #8E8E8E
      line-height: 24px
      background: 0
      transition: all 0.2s ease-out

    &:focus
      border: none
      color: #fff
      outline: 0 !important
      opacity: 1

      &::-webkit-input-placeholder
        color: #4E4E4E

      &::-moz-placeholder
        color: #4E4E4E

      &:-ms-input-placeholder
        color: #4E4E4E

      &::-ms-input-placeholder
        color: #4E4E4E

      &::placeholder
        color: #4E4E4E

    &:hover
      opacity: 1
      background: #101010

    &:disabled
      opacity: .5 !important

  .input
    &::-webkit-search-decoration
      -webkit-appearance: none

@media screen and (max-width: 1200px)
  .platformSetting
    .containerPlatformSetting
      padding-bottom: 20px

      .settingBlock
        .platformSetting
          gap: 20px

          .platformSettingItem
            padding: 20px

            .platformFormItemGroup
              .platformFormItem
                flex-direction: column

                .platformFormItemTitle
                  width: 100%

                .platformFormItemInput
                  width: 100%

            .platformSettingName
              padding-bottom: 24px
              margin-bottom: 20px

              h2
                text-align: center

            .platformSettingAccount
              flex-direction: column
              gap: 20px

              .platformAvatarUpload
                flex-direction: column
                align-items: center
                text-align: center

              .platformAvatarBlock
                flex-direction: column
                align-items: center
                justify-content: center

              .platformAvatarBlock
                img
                  width: 150px
                  height: 150px
                  border-radius: 150px

            .buttonBlock
              .button
                width: 100%
