.button, button
  align-items: center
  appearance: none
  border-width: 0
  cursor: pointer
  display: flex
  font-size: 14px
  font-weight: 600
  justify-content: center
  line-height: 24px
  padding: 12px 32px
  text-align: center
  text-decoration: none
  white-space: nowrap
  transition: all .2s ease-out