.statisticBlock
  width: 100%
  background: linear-gradient(148deg, #101010 7.86%, #111 93.56%)
  display: inline-table
  flex-direction: column
  height: auto
  overflow: hidden
  position: relative
  padding: 20px
  margin: 0
  border-radius: 10px
  box-sizing: border-box

  &:before
    background: linear-gradient(132deg, #313131 15%, #101010 30%)
    border-radius: 10px
    content: ""
    inset: 0
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)
    -webkit-mask-composite: xor
    mask-composite: exclude
    padding: 1px
    position: absolute
    width: calc(100% - 2px)
    z-index: 0
    opacity: 0.25

  .statisticBlockHeader
    position: relative
    display: flex

  .statsBlock
    display: flex
    gap: 25px
    padding: 20px 0
    box-sizing: border-box
    position: relative
    border-bottom: 1px solid rgba(255, 255, 255, 0.05)
    margin-bottom: 25px
    z-index: 1

    .statsItem
      display: flex
      flex-direction: column
      position: relative
      gap: 2px

      .statsItemInfo
        font-size: 14px
        font-style: normal
        font-weight: 400
        line-height: normal
        color: #fff
        text-transform: capitalize
        display: flex
        gap: 6px
        transition: all .2s ease-out

        span
          text-transform: initial

        i
          opacity: 1
          transition: all .2s ease-out
          cursor: pointer

          &:hover
            opacity: 0.5

      .statsItemDesc
        font-size: 8px
        font-style: normal
        font-weight: 400
        line-height: 18px
        color: rgba(255, 255, 255, 0.25)
        text-transform: uppercase

  .statisticBlockPeriod
    display: flex
    flex-direction: column
    gap: 16px
    height: fit-content
    position: relative
    margin-top: 20px

    .statisticBlockPeriodTitle
      color: #8E8E8E

    .statisticBlockPeriodList
      display: flex
      justify-content: space-between

      .statisticBlockPeriodItem
        display: flex
        height: 100%
        width: 20px
        position: relative
        flex-shrink: 0

        &:nth-child(n+13)
          display: none

        &.active
          .statisticBlockPeriodBtn
            .statisticBlockPeriodColumn
              background: #3968FF

            .statisticBlockPeriodMonth
              color: #fff
              opacity: 1

            .statisticBlockPeriodBeautiful
              opacity: 1

        .statisticBlockPeriodBtn
          display: flex
          width: 20px
          height: 102px
          flex-direction: column
          align-items: center
          justify-content: end
          gap: 6px
          cursor: pointer
          transition: all .2s ease-out

          &:hover
            opacity: 1

          .statisticBlockPeriodBeautiful
            display: flex
            color: #3968FF
            opacity: 0
            transition: all .2s ease-out
            font-size: 8px
            line-height: 8px
            font-weight: 600

          .statisticBlockPeriodTotal
            display: none

          .statisticBlockPeriodColumn
            width: 100%
            height: auto
            border-radius: 4px 4px 0 0
            background: rgba(255, 255, 255, 0.05)
            position: relative
            display: block
            transition: all .2s ease-out
            flex-shrink: 0

          .statisticBlockPeriodMonth
            text-align: center
            opacity: .5
            transition: all .2s ease-out
            font-size: 8px
            line-height: 8px
            font-weight: 600
            color: rgba(255, 255, 255, 0.25)

          &:hover

            .statisticBlockPeriodColumn
              background: #3968FF

            .statisticBlockPeriodMonth
              color: #fff
              opacity: 1

            .statisticBlockPeriodBeautiful
              opacity: 1

  .totalBlock
    display: flex
    gap: 10px
    flex-direction: column
    width: 100%
    position: relative
    z-index: 1
    margin-top: -10px

    .totalNumber
      display: flex
      gap: 8px
      align-items: flex-end
      height: auto
      cursor: pointer

      &.noLink
        cursor: auto

      span
        font-size: 40px
        font-style: normal
        font-weight: 400
        line-height: normal

      p
        font-size: 27px
        font-style: normal
        font-weight: 400
        line-height: normal
        margin-bottom: 2px

    .totalButton
      display: flex
      gap: 8px
      align-items: center
      margin-top: -6px

      p
        font-size: 10px
        font-style: normal
        font-weight: 400
        line-height: 18px
        color: rgba(255, 255, 255, 0.25)
        text-transform: uppercase

      i
        opacity: 1
        transition: all .2s ease-out
        cursor: pointer

        &:hover
          opacity: 0.5

//Иконки
i
  &.iconInfo
    &::before
      content: ""
      border: none
      display: inline-block
      flex-grow: 0
      flex-shrink: 0
      height: 17px
      width: 17px
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='17' fill='none' viewBox='0 0 17 17'%3E%3Crect width='17' height='17' fill='%23fff' fill-opacity='.05' rx='8.5'/%3E%3Cpath fill='%23fff' fill-opacity='.5' d='M8.24 10.974c-.522 0-.945.403-.945.888 0 .473.41.888.945.888.535 0 .958-.415.958-.888 0-.485-.436-.888-.958-.888Zm.162-6.724c-1.68 0-2.45.923-2.45 1.545 0 .45.41.658.745.658.672 0 .399-.888 1.667-.888.622 0 1.12.254 1.12.784 0 .623-.697.98-1.107 1.303-.361.289-.834.761-.834 1.753 0 .6.175.773.685.773.609 0 .733-.254.733-.473 0-.6.013-.946.697-1.441.336-.243 1.393-1.027 1.393-2.111 0-1.084-1.057-1.903-2.65-1.903Z'/%3E%3C/svg%3E%0A")
      background-position: 50%
      background-repeat: no-repeat
      background-size: 100%
      transition: all .2s ease-out