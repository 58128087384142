.users
  position: relative
  display: block

  .containerUsers
    position: relative
    max-width: 1052px
    margin: 0 auto
    padding-bottom: 126px

    .searchBlock
      position: relative
      margin-bottom: 24px

      .searchField
        display: flex
        position: relative
        width: 100%

        .searchLens
          position: absolute
          top: 12px
          left: 20px

        .searchClose
          position: absolute
          top: 12px
          right: 20px
          cursor: pointer
          opacity: 0.5
          transition: all 0.2s ease-out

          &:hover
            opacity: 1

        .searchInput
          width: 100%
          padding: 12px 20px 12px 56px
          border: none
          border-radius: 10px
          font-weight: 400
          font-size: 14px
          color: #fff
          opacity: 1
          line-height: 24px
          background: #101010
          transition: all 0.2s ease-out
          margin: 0

    .usersList
      .userBlockSkeleton
        grid-gap: 10px
        display: grid
        grid-template-columns: repeat(3, 1fr)
        grid-template-rows: auto
        position: relative

        svg
          background: rgb(255 255 255 / 5%)
          border-radius: 10px

      .usersBlock
        grid-gap: 10px
        display: grid
        grid-template-columns: repeat(3, 1fr)
        grid-template-rows: auto
        position: relative

        .usersBlockItem
          grid-column: span 1
          width: 100%
          border-radius: 10px
          transition: all .2s ease-out
          position: relative
          overflow: hidden
          background-color: #101010

          .usersBg
            position: absolute
            top: -30px
            left: -30px
            z-index: 0
            opacity: 0
            transition: all .2s ease-out

            img
              filter: blur(12.5px)
              opacity: 0.2
              display: flex
              position: relative

            &:before
              content: ''
              width: 100%
              height: 74px
              background: linear-gradient(278deg, #101010 25%, rgba(0, 0, 0, 0) 100%)
              z-index: 1
              display: flex
              position: absolute
              top: 30px
              left: 30px

          .userInfo
            padding: 17px 20px
            display: flex
            align-items: center
            gap: 16px
            position: relative
            z-index: 2

            .userAvatar
              border-radius: 40px
              overflow: hidden
              display: flex
              align-items: center
              justify-content: center
              width: 40px
              height: 40px
              flex-shrink: 0

              img
                width: 40px
                height: 40px
                object-fit: cover

            .userName
              width: 100%
              overflow: hidden
              display: flex
              flex-direction: column
              gap: 4px

              a
                text-decoration: none
                color: #fff

                &:after
                  bottom: 0
                  content: ""
                  left: 0
                  position: absolute
                  right: 0
                  top: 0

                h3
                  flex-shrink: 0
                  width: 100%
                  white-space: nowrap
                  text-overflow: ellipsis
                  overflow: hidden
                  font-size: 16px
                  font-style: normal
                  font-weight: 400
                  line-height: 20px
                  transition: all .2s ease-out

              p
                color: rgba(255, 255, 255, 0.50)
                font-size: 12px
                font-style: normal
                font-weight: 400
                line-height: 16px

          &:hover
            background: #101010

            .usersBg
              opacity: 1

            .userInfo
              .userName
                h3
                  color: #3968FF

          &:active, &.active
            background: #101010

            .user-info
              opacity: 1

          &:disabled, &.disabled
            background: #000
            pointer-events: none
            opacity: 0.5

        .info
          grid-column: 1 / span 4
          color: #8E8E8E
          font-weight: 400
          font-size: 14px
          line-height: 24px

      .user-edit
        display: flex
        flex-direction: column
        gap: 30px

        .user-info
          padding: 0
          display: flex
          align-items: center
          gap: 16px

          .user-avatar
            border-radius: 50px
            overflow: hidden
            display: flex
            align-items: center
            position: relative
            justify-content: center
            width: 80px
            height: 80px
            flex-shrink: 0

            img
              width: 80px
              height: 80px
              object-fit: cover

            .btn-avatar
              position: absolute
              top: 0
              left: 0
              right: 0
              bottom: 0
              width: 100%
              height: 100%

              &:after
                content: ''
                background: #1E1E1E
                position: absolute
                top: 0
                left: 0
                width: 100%
                height: 100%
                opacity: 0.5
                z-index: 0

              button
                background: transparent
                z-index: 2
                position: absolute
                top: 0
                left: 0
                right: 0
                bottom: 0

          .user-name
            width: 100%
            display: flex
            flex-direction: column
            gap: 6px

            a
              text-decoration: none

              &:after
                bottom: 0
                content: ""
                left: 0
                position: absolute
                right: 0
                top: 0

            p
              color: #8E8E8E

        form
          h3
            margin-bottom: 24px

          .grid-input
            grid-gap: 24px
            display: grid
            grid-template-columns: repeat(2, 1fr)
            grid-template-rows: auto
            margin-bottom: 36px

            label
              position: relative

              p
                margin-bottom: 12px
                color: #8E8E8E

              input
                box-sizing: border-box
                width: 100%

              .grid-input-pass
                position: relative

              .password-toggle
                cursor: pointer
                background: none
                border: none
                position: absolute
                right: 0
                top: 0
                margin: 0
                padding: 12px
                opacity: 0.5
                transition: all .2s ease-out

                &:hover
                  opacity: 1

                &:active
                  opacity: 0.8


          .button-block
            display: flex
            margin-top: 40px
            align-items: center
            gap: 40px
            justify-content: space-between

            .delete-user-btn
              display: flex
              align-items: center
              gap: 12px
              color: #fff
              opacity: 1
              transition: all .2s ease-out
              cursor: pointer
              border-radius: 10px
              background: #101010
              padding: 12px 20px

              span
                opacity: 0.5
                transition: all .2s ease-out

              i
                opacity: 0.5
                transition: all .2s ease-out

                &:before
                  width: 20px
                  height: 20px
                  background-size: 20px

              &:hover
                i
                  opacity: 1

                span
                  opacity: 1

              &:active
                i
                  opacity: 1

                span
                  opacity: 1

// Иконки
i
  display: flex

  &.iconClose
    &::before
      content: ""
      border: none
      display: inline-block
      flex-grow: 0
      flex-shrink: 0
      height: 24px
      width: 24px
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'%3E%3Cpath stroke='%23fff' stroke-linecap='round' stroke-width='1.5' d='m16 8-8 8m0-8 8 8'/%3E%3C/svg%3E")
      background-position: 50%
      background-repeat: no-repeat
      background-size: 24px
      transition: all .2s ease-out

  &.iconSearch
    &::before
      content: ""
      border: none
      display: inline-block
      flex-grow: 0
      flex-shrink: 0
      height: 24px
      width: 24px
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'%3E%3Cpath fill='%23fff' d='M19.805 18.864 15.83 14.89a6.669 6.669 0 1 0-.942.941l3.974 3.974a.666.666 0 0 0 .942-.941Zm-9.126-2.858a5.326 5.326 0 1 1 0-10.652 5.326 5.326 0 0 1 0 10.652Z'/%3E%3C/svg%3E")
      background-position: 50%
      background-repeat: no-repeat
      background-size: 24px
      transition: all .2s ease-out
      opacity: 0.5

@media screen and (max-width: 1200px)
  .users
    .containerUsers
      padding-bottom: 20px

      .searchBlock
        margin-bottom: 20px

      .usersList
        .usersBlock
          .usersBlockItem
            grid-column: span 3