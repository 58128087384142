.topProductList
  display: flex
  gap: 20px
  flex-direction: column

.info
  color: #8E8E8E
  font-weight: 400
  font-size: 14px
  line-height: 24px
  margin-top: 0
  display: flex
  box-sizing: border-box