.merch
  position: relative
  display: block

  .containerMerch
    position: relative
    max-width: 1052px
    margin: 0 auto

    .content
      grid-gap: 24px
      display: grid
      grid-template-columns: repeat(12, 1fr)
      grid-template-rows: auto
      position: relative
      margin-bottom: 126px

      .contentOrders
        grid-column: span 8
        width: 100%
        display: flex
        flex-direction: column
        position: relative
        box-sizing: border-box
        gap: 24px
        height: fit-content

        .contentOrdersBlock
          background: linear-gradient(148deg, #101010 7.86%, #111 93.56%)
          border-radius: 10px
          width: 100%
          padding: 20px
          margin: 0
          box-sizing: border-box
          position: relative

          &:before
            background: linear-gradient(132deg, #313131 15%, #101010 30%)
            border-radius: 10px
            content: ""
            inset: 0
            -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)
            -webkit-mask-composite: xor
            mask-composite: exclude
            padding: 1px
            position: absolute
            width: calc(100% - 2px)
            z-index: 0
            opacity: 0.25

          .contentOrdersBlockTitle
            display: flex
            gap: 6px
            width: 100%
            position: relative
            border-bottom: 1px solid rgba(255, 255, 255, 0.05)
            padding-bottom: 20px
            margin-bottom: 20px

            .titleOrdersBlock
              display: flex
              gap: 6px
              flex-direction: column
              width: 100%
              position: relative

              h2
                font-size: 18px
                font-style: normal
                font-weight: 600
                line-height: normal

              .contentOrdersBlockPeriod
                font-size: 10px
                font-style: normal
                font-weight: 400
                line-height: 18px
                color: rgba(255, 255, 255, 0.25)
                text-transform: uppercase

            &.orderTitle
              margin-bottom: 0

            .bittonAllOrdersBlock
              display: block
              position: relative
              flex-shrink: 0

              .link
                display: flex
                padding: 9px 18px
                border-radius: 8px
                background: rgba(255, 255, 255, 0.05)
                font-size: 12px
                font-style: normal
                font-weight: 400
                line-height: normal
                box-sizing: border-box
                align-items: center
                justify-content: center
                text-decoration: none
                color: rgba(255, 255, 255, 0.5)

                &:hover
                  color: #fff
                  opacity: 0.8

    .topProduct
      position: relative
      margin-top: 0

      .orders
        position: relative
        margin-top: -20px

    .rightBlock
      grid-column: span 4
      width: 100%
      position: relative
      gap: 24px
      display: flex
      flex-direction: column

@media screen and (max-width: 1200px)
  .merch
    .containerMerch
      width: 100%
      max-width: 375px

      .content
        grid-gap: 20px
        margin-bottom: 20px

        .rightBlock
          grid-column: span 12
          gap: 20px

        .contentOrders
          grid-column: span 12
          order: 1