.userFormSelectCollectionBlock
  display: flex
  gap: 6px

  .select
    width: 100%

  .addButton
    width: 48px
    height: 48px
    background: #1D1D1D
    border-radius: 8px
    display: flex
    align-items: center
    justify-content: center
    box-sizing: border-box
    position: relative
    flex-shrink: 0
    transition: all 0.2s ease-out
    cursor: pointer

    &:hover
      opacity: 0.8

  .removeButton
    width: 48px
    height: 48px
    background: #1D1D1D
    border-radius: 8px
    display: flex
    align-items: center
    justify-content: center
    box-sizing: border-box
    position: relative
    flex-shrink: 0
    transition: all 0.2s ease-out
    cursor: pointer

    &:hover
      opacity: 0.8

//Иконки
i
  &.iconDelete
    &::before
      content: ""
      border: none
      display: inline-block
      flex-grow: 0
      flex-shrink: 0
      height: 24px
      width: 24px
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none' viewBox='0 0 20 20'%3E%3Cpath stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.667' d='m6.666 6.667 6.667 6.666m0-6.666-6.667 6.666'/%3E%3C/svg%3E%0A")
      background-position: 50%
      background-repeat: no-repeat
      background-size: 24px
      transition: all .2s ease-out

  &.iconAdd
    &::before
      content: ""
      border: none
      display: inline-block
      flex-grow: 0
      flex-shrink: 0
      height: 24px
      width: 24px
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none' viewBox='0 0 20 20'%3E%3Cpath stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.667' d='M5.285 10h9.428M10 5.286v9.428'/%3E%3C/svg%3E%0A")
      background-position: 50%
      background-repeat: no-repeat
      background-size: 24px
      transition: all .2s ease-out