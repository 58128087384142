.userSetting
  position: relative
  display: block

  .containerUserSetting
    position: relative
    max-width: 1052px
    margin: 0 auto
    padding-bottom: 126px

    .settingBlock
      display: flex
      flex-direction: column

@media screen and (max-width: 1200px)
  .userSetting
    .containerUserSetting
      padding-bottom: 20px