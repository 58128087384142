.statsYearBlock
  width: 100%
  background: linear-gradient(148deg, #101010 7.86%, #111 93.56%)
  display: inline-table
  flex-direction: column
  height: auto
  overflow: hidden
  position: relative
  padding: 20px
  margin: 0
  border-radius: 10px
  box-sizing: border-box

  .statsYearTitle
    display: flex
    width: 100%
    position: relative
    margin-bottom: 10px
    z-index: 1

    h3
      font-size: 10px
      font-style: normal
      font-weight: 400
      line-height: 18px
      color: rgba(255, 255, 255, 0.25)
      text-transform: uppercase

  .statsYearTotal
    display: flex
    gap: 6px
    align-items: flex-end
    font-size: 30px
    font-style: normal
    font-weight: 400
    line-height: 30px
    margin-bottom: 20px
    position: relative
    z-index: 1

    .totalCurrent
      display: flex
      font-size: 25px
      font-style: normal
      font-weight: 400
      line-height: normal
      margin-bottom: -3px

  .buttonYear
    display: flex
    padding: 4px
    gap: 4px
    box-sizing: border-box
    border-radius: 14px
    background: rgba(0, 0, 0, 0.25)
    position: relative
    z-index: 1

    &:before
      content: ''
      position: absolute
      top: 0
      bottom: 0

      margin-top: auto
      margin-bottom: auto
      width: 50%
      height: calc(100% - 8px)
      background: rgba(255, 255, 255, 0.05)
      border-radius: 10px
      transition: all 0.2s ease-out

    &.current
      &:before
        left: 4px

      .buttonOld
        opacity: 0.5

    &.old
      &:before
        left: calc(50% - 4px)

      .buttonCurrent
        opacity: 0.5

    .button
      color: #fff
      padding: 2px
      border-radius: 10px
      background: transparent
      width: 50%
      font-size: 12px
      font-style: normal
      font-weight: 600
      line-height: 20px
      text-align: center
      cursor: pointer
      transition: all 0.2s ease-out
      z-index: 2

      &:hover
        opacity: 1

  &:before
    background: linear-gradient(132deg, #313131 15%, #101010 30%)
    border-radius: 10px
    content: ""
    inset: 0
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)
    -webkit-mask-composite: xor
    mask-composite: exclude
    padding: 1px
    position: absolute
    width: calc(100% - 2px)
    z-index: 0
    opacity: 0.25

  .stats-collection-year
    .stats-collection-year-clarify
      margin-bottom: 0
      display: flex
      gap: 6px
      align-items: center
      justify-content: start

      i
        margin-top: 0
        position: relative
        display: flex

        &:before
          width: 10px
          height: 10px
          background-size: 10px

      span
        color: #fff
        text-transform: uppercase

  .stats-wrapper
    display: flex
    flex-direction: column
    padding: 20px
    gap: 14px

  .period-totalyear-block
    display: flex
    flex-direction: column
    margin-bottom: 0
    gap: 8px
    cursor: pointer

    .period-total
      display: flex
      align-items: baseline
      gap: 8px

    .period-block-title
      color: #8E8E8E
      display: flex
      gap: 6px
      align-items: center

      i
        opacity: .5