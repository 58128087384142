.bgBlock
  width: 100%
  height: 100vh
  background: #000
  display: flex
  align-items: center
  justify-content: center
  opacity: 1

  .bgActive
    background: #000
    width: 100vw !important
    height: 100vh !important

    canvas
      width: 100vw !important
      height: 100vh !important
      background: #000