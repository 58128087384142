.preloader
  display: flex
  align-items: center
  justify-content: center
  height: 100vh
  opacity: 1
  transition: opacity 0.1s
  position: absolute
  top: 0
  left: 0
  width: 100%
  background: #000
  z-index: 99

  &.hidden
    opacity: 0
    height: 0
    top: 0
    z-index: -1