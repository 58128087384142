.header
  position: relative
  border-bottom: 1px solid rgba(255, 255, 255, 0.05)
  margin-bottom: 30px

  .wrapper
    padding: 18px 0
    display: flex
    gap: 12px

    .titleBlock
      border-radius: 10px
      background: linear-gradient(148deg, #101010 7.86%, #111 93.56%)
      width: 100%
      padding: 5px 6px 6px 24px
      display: flex
      align-items: center
      justify-content: space-between
      gap: 16px
      box-sizing: border-box
      max-height: 48px

      .titlePage
        h1
          font-size: 16px
          font-style: normal
          font-weight: 400
          line-height: normal

      .titleAction
        display: flex
        gap: 6px
        align-items: center

        .titleActionButton
          padding: 10px 18px
          border-radius: 6px
          cursor: pointer
          color: #ffffff
          text-decoration: none
          opacity: 0.5
          font-size: 14px
          font-style: normal
          font-weight: 600
          line-height: normal

          &:hover
            background: rgba(255, 255, 255, 0.05)
            opacity: 1

          &.active
            background: rgba(255, 255, 255, 0.05)
            opacity: 1

@media screen and (max-width: 1200px)
  .header
    position: relative
    border-bottom: 1px solid rgba(255, 255, 255, 0.05)
    margin-bottom: 20px

    .wrapper
      padding: 18px 0
      display: flex
      gap: 12px

      .buttonBackBlock
        display: none

      .titleBlock
        background: transparent
        padding: 0
        width: 100%
        box-sizing: border-box

        .titleAction
          width: 100%
          overflow-x: auto

          .titleActionButton
            flex-shrink: 0
            padding: 16px 18px
            border-radius: 10px

        .titlePage
          display: none
