@import 'components/ui/fonts'
@import './components/all'

*
  --scrollbar-foreground: #1a1a1a
  --scrollbar-background: #000
  --scrollbar-size: 16px
  scrollbar-color: var(--scrollbar-foreground) var(--scrollbar-background)

  &::-webkit-scrollbar
    width: var(--scrollbar-size)
    height: var(--scrollbar-size)

  &::-webkit-scrollbar-thumb
    border-radius: 10px
    border: 6px solid #000
    background: var(--scrollbar-foreground)

  &::-webkit-scrollbar-track
    border-radius: 0px
    background: var(--scrollbar-background)

*:focus
  outline: none

html
  //margin-left: calc(100vw - 100% - 16px)
  margin-left: 0px
  margin-right: 0px
  font-size: 16px
  height: 100%
  scroll-behavior: smooth
  color-scheme: dark

body
  background-color: #000
  color: #fff
  font-family: "RF Dewi", sans-serif !important
  margin: 0
  outline: 0

a, abbr, acronym, address, applet, article, audio, b, big, blockquote, body, canvas, caption, center, cite, code, dd, del, details, dfn, div, dl, dt, em, embed, fieldset, figcaption, figure, form, h1, h2, h3, h4, h5, h6, hgroup, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, nav, object, ol, output, p, pre, q, ruby, s, samp, section, small, span, strike, strong, sub, summary, sup, table, tbody, td, tfoot, th, thead, time, tr, tt, u, ul, var, video
  margin: 0
  padding: 0
  vertical-align: baseline

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section
  display: block

hr
  background-color: #2E2E2E
  border: 0
  height: 2px

ol, ul
  list-style: none

table
  border-collapse: collapse
  border-spacing: 0

a
  transition: all .2s ease-out

  &:hover
    opacity: 0.8

a:active, a:focus, a:hover
  outline: 0

.main
  box-sizing: border-box
  display: block
  min-height: 100%
  position: relative

main
  box-sizing: border-box
  display: block
  min-height: 100%
  position: relative
  padding: 0 24px
  margin-left: 290px
  width: calc(100% - 290px)
  overflow: hidden

  .content
    grid-gap: 24px
    display: grid
    grid-template-columns: repeat(12, 1fr)
    grid-template-rows: auto
    position: relative

.container
  margin-left: auto
  margin-right: auto
  padding-left: calc(1.5rem * .5)
  padding-right: calc(1.5rem * .5)
  width: 100%

.containerFluid
  margin-left: auto
  margin-right: auto
  padding-left: 0
  padding-right: 0
  width: 100%
  box-sizing: border-box

@media screen and (min-width: 1200px) and (max-width: 1400px)
  main
    margin-left: 96px
    width: calc(100% - 96px)

@media screen and (max-width: 1200px)
  main
    width: 100%
    max-width: 375px
    margin: 0 auto
    margin-left: inherit
    margin-top: 87px
    padding: 0 10px