.buttonBackBlock
  border-radius: 10px
  background: linear-gradient(148deg, #101010 7.86%, #111 93.56%)
  padding: 14px
  display: flex
  align-items: center
  justify-content: center
  max-height: 48px
  max-width: 48px
  box-sizing: border-box

// Иконки
i
  &.iconBack
    content: ''
    border: none
    display: inline-block
    flex-grow: 0
    flex-shrink: 0
    height: 20px
    width: 20px
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21' height='20' fill='none' viewBox='0 0 21 20'%3E%3Cpath fill='%23fff' d='M17.375 9.375H4.907L9.68 4.827a.625.625 0 1 0-.862-.905L3.366 9.116A1.243 1.243 0 0 0 3 10c0 .334.13.648.377.894l5.442 5.184a.622.622 0 0 0 .782.065.625.625 0 0 0 .08-.97l-4.794-4.548h12.488a.624.624 0 1 0 0-1.25Z'/%3E%3C/svg%3E%0A")
    background-position: 50%
    background-repeat: no-repeat
    background-size: 100%
    transition: all 0.2s ease-out

@media screen and (max-width: 1200px)
  .buttonBackBlock
    display: none