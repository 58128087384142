.sellerBlock
  position: relative
  display: flex

  .sellerBlockBackground
    position: absolute
    top: -40px
    left: -40px
    filter: blur(40px)
    z-index: 0
    transition: all .2s ease-out

    img
      width: 250px
      height: auto
      opacity: 0.3
      top: -50px
      left: -50px
      position: absolute
      border-radius: 250px
      transition: all .2s ease-out

    &:before
      content: ''
      width: 180px
      height: 180px
      background: linear-gradient(132deg, rgb(0 255 45 / 0%) 0%, rgb(16 16 16 / 50%) 50%)
      display: flex
      position: absolute
      top: 20px
      left: 20px
      z-index: 1
      transition: all .2s ease-out

  .sellerBlockInfo
    position: relative
    display: flex
    gap: 20px
    align-items: center
    z-index: 1

    .sellerBlockAvatar
      position: relative
      display: flex
      border-radius: 8px
      overflow: hidden

      img
        width: 70px
        height: 90px
        object-fit: cover

    .sellerBlockName
      display: flex
      flex-direction: column
      gap: 4px

      .sellerBlockNameHeader
        display: flex
        position: relative
        gap: 10px
        align-items: center

        h2
          font-size: 18px
          font-style: normal
          font-weight: 600
          line-height: 25px

        .sellerBlockNameHeaderArrow
          display: flex
          position: relative
          cursor: pointer
          opacity: 0.5
          transition: all .2s ease-out

          &:hover
            opacity: 1

      .sellerBlockNameTitle
        color: rgba(255, 255, 255, 0.25)
        font-size: 10px
        font-style: normal
        font-weight: 400
        line-height: 18px
        text-transform: uppercase

//Иконки
i
  &.iconArrow
    &::before
      content: ""
      border: none
      display: inline-block
      flex-grow: 0
      flex-shrink: 0
      height: 20px
      width: 20px
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none' viewBox='0 0 20 20'%3E%3Crect width='20' height='20' fill='%23fff' fill-opacity='.1' rx='10'/%3E%3Cpath stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.25' d='M6.666 9.167 9.999 12.5l3.334-3.333'/%3E%3C/svg%3E%0A")
      background-position: 50%
      background-repeat: no-repeat
      background-size: 100%
      transition: all .2s ease-out