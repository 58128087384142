.orderBlock
  padding: 20px 0
  border-bottom: 1px solid #1a1a1a
  margin-top: 0
  display: flex
  align-items: flex-start
  justify-content: space-between
  transition: all 0.2s ease-out
  gap: 20px

  &:last-child
    padding-bottom: 0
    border-bottom: none

  .orderBlockInfo
    display: flex
    flex-direction: column
    gap: 16px
    flex-shrink: 0

    .orderBlockTitle
      display: flex
      align-items: flex-start
      gap: 16px

      .orderBlockSum
        display: flex
        position: relative

        span
          font-size: 16px
          font-style: normal
          font-weight: 400
          line-height: 16px

      .orderBlockOther
        gap: 16px
        display: flex
        margin-top: -2px

        .orderBlockDate
          text-transform: uppercase
          color: #4C4C4C
          display: flex
          align-items: center
          font-size: 12px
          font-style: normal
          font-weight: 400
          line-height: normal

        .orderBlockCount
          display: flex
          gap: 0
          text-transform: uppercase
          color: #4C4C4C
          display: flex
          align-items: center
          font-size: 12px
          font-style: normal
          font-weight: 400
          line-height: normal

          span
            margin-left: 2px
            text-transform: lowercase

        .orderBlockNumb
          text-transform: uppercase
          color: #4C4C4C
          display: flex
          align-items: center
          font-size: 12px
          font-style: normal
          font-weight: 400
          line-height: normal

    .orderBlockList
      display: flex
      position: relative

      //.orderItem
      //  display: flex
      //  flex-direction: column
      //  gap: 8px
      //  align-items: flex-start
      //  margin-left: -8px

      $element-count: 50

      @for $i from 1 through $element-count
        .orderItem:nth-child(#{$i})
          display: flex
          flex-direction: column
          gap: 8px
          align-items: flex-start
          margin-left: -8px
          z-index: #{$element-count - $i + 1}

          &:first-child
            margin-left: 0

          .orderItemImg
            display: flex
            border-radius: 8px
            overflow: hidden
            border: 2px solid #111111
            background: #111111

            a
              display: flex
              transition: all .2s ease-out
              border-radius: 6px
              overflow: hidden

              &:hover
                opacity: 0.8

              &:active
                opacity: 0.5

              img
                width: 30px
                height: 30px
                background: #111111
                color: #fff

  .orderItemList
    display: flex
    gap: 8px
    flex-wrap: wrap
    justify-content: flex-end
    align-items: flex-end
    flex-direction: column

    .orderItem
      display: flex
      gap: 8px
      align-items: flex-start

      p
        font-size: 12px
        font-style: normal
        font-weight: 400
        line-height: 18px
        color: rgba(255, 255, 255, 0.25)

      .orderItemSize
        display: flex
        align-items: center
        justify-content: center

        span
          padding: 0 5px
          border-radius: 4px
          background: rgba(255, 255, 255, 0.05)
          color: rgba(255, 255, 255, 0.5)
          font-size: 10px
          font-style: normal
          font-weight: 400
          line-height: 18px

@media screen and (max-width: 1200px)
  .orderBlock
    flex-direction: column

    .orderBlockInfo
      flex-direction: row
      justify-content: space-between
      width: 100%
      position: relative

      .orderBlockList
        position: absolute
        right: 0
        top: 8px
        flex-direction: column

        $element-count: 50

        @for $i from 1 through $element-count
          .orderItem:nth-child(#{$i})
            display: flex
            flex-direction: column
            gap: 8px
            align-items: flex-start
            margin-left: 0
            margin-top: -8px
            z-index: #{$element-count - $i + 1}

    .orderItemList
      justify-content: flex-start
      align-items: flex-start
