.toastHot
  background: linear-gradient(148deg, #101010 7.86%, #111 93.56%)
  border: none
  display: flex
  height: fit-content
  overflow: hidden
  position: relative
  box-sizing: border-box
  padding: 20px
  border-radius: 12px
  top: auto
  gap: 18px
  color: #fff

  .toastIconError
    padding: 20px
    background: transparent
    border-radius: 100px
    display: flex
    align-items: center
    justify-content: center
    position: relative
    box-sizing: border-box
    border: 1px solid
    border-color: rgb(255 255 255 / 5%)
    border-right-color: rgb(255 255 255 / 5%)
    animation: none

    &::before
      content: ''
      position: absolute
      width: 16px
      height: 16px
      display: flex
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' viewBox='0 0 16 16'%3E%3Cg clip-path='url(%23a)'%3E%3Cpath fill='%23fff' d='M7.827 0C8.87 0 9.715.844 9.715 1.888a1.887 1.887 0 0 1-1.888 1.889 1.887 1.887 0 0 1-1.889-1.889C5.938.844 6.783 0 7.827 0Zm1.718 5.961a1 1 0 0 0-1-1H6.347a1.122 1.122 0 1 0 0 2.245h.112v6.546h-.234a1 1 0 0 0-1 1V15a1 1 0 0 0 1 1h3.55a1 1 0 0 0 1-1v-.248a1 1 0 0 0-1-1h-.227L9.545 5.96Z'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' d='M0 0h16v16H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A")
      background-size: 100%
      background-position: 50%
      background-repeat: no-repeat
      transform: none
      animation: none
      top: 0
      left: 0
      right: 0
      bottom: 0
      margin: auto
      opacity: 1

  .toastIconSuccess
    padding: 20px
    background: transparent
    border-radius: 100px
    display: flex
    align-items: center
    justify-content: center
    position: relative
    box-sizing: border-box
    border: 1px solid rgb(255 255 255 / 5%)
    animation: none

    &::before
      content: ''
      position: absolute
      width: 16px
      height: 16px
      display: flex
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' viewBox='0 0 16 16'%3E%3Cpath fill='%23fff' d='M14.56 2.515a1 1 0 0 0-1.415-.055L6.458 8.664a1 1 0 0 1-1.41-.05L2.964 6.392a1 1 0 0 0-1.413-.046l-.821.77a1 1 0 0 0-.046 1.413l4.206 4.489a1 1 0 0 0 1.41.05l8.968-8.314a1 1 0 0 0 .055-1.411l-.763-.827Z'/%3E%3C/svg%3E%0A")
      background-size: 100%
      background-position: 50%
      background-repeat: no-repeat
      transform: none
      animation: none
      top: 0
      left: 0
      right: 0
      bottom: 0
      margin: auto
      opacity: 1

  .toastTitle
    display: flex
    gap: 6px
    flex-direction: column
    font-size: 16px
    font-style: normal
    font-weight: 600
    line-height: normal
    padding: 0
    margin: 0

    p
      font-size: 12px
      font-style: normal
      font-weight: 400
      line-height: normal
      color: rgba(173, 173, 173, 1)