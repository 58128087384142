.orderList
  display: flex
  flex-direction: column
  position: relative
  overflow: hidden
  width: 100%

.info
  color: #8E8E8E
  font-weight: 400
  font-size: 14px
  line-height: 24px
  margin-top: 20px