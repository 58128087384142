// Размеры шрифтов
h1
  font-family: 'RF Dewi', sans-serif !important
  font-weight: 700
  font-size: 1.5rem
  line-height: 36px
  color: #fff

h2
  font-family: 'RF Dewi', sans-serif !important
  font-weight: 700
  font-size: 1.25rem
  line-height: 30px
  color: #fff

h3
  font-family: 'RF Dewi', sans-serif !important
  font-weight: 600
  font-size: 1rem
  line-height: 24px
  color: #fff

h4
  font-family: 'RF Dewi', sans-serif !important
  font-weight: 600
  font-size: 1.15rem
  line-height: 24px
  color: #fff

h1, h2, h3, h4, p, span, div, li, a, button
  &.body-1
    font-family: 'RF Dewi', sans-serif !important
    font-weight: 400
    font-size: .875rem
    line-height: 24px
    color: #fff

  &.body-2
    font-family: 'RF Dewi', sans-serif !important
    font-weight: 400
    font-size: .75rem
    line-height: 20px
    color: #fff

  &.caption-1
    font-family: 'RF Dewi', sans-serif !important
    font-weight: 400
    font-size: 1.125rem
    line-height: 24px
    color: #fff

  &.caption-2
    font-family: 'RF Dewi', sans-serif !important
    font-weight: 400
    font-size: 0.85rem
    line-height: 24px
    color: #fff

  &.caption-3
    font-family: 'RF Dewi', sans-serif !important
    font-weight: 400
    font-size: .75rem
    line-height: 20px
    color: #fff

  &.caption-4
    font-family: 'RF Dewi', sans-serif !important
    font-weight: 400
    font-size: .65rem
    line-height: 18px
    color: #fff

  &.caption-5
    font-family: 'RF Dewi', sans-serif !important
    font-weight: 400
    font-size: .375rem
    line-height: 12px
    color: #fff

  &.title-1
    font-family: 'RF Dewi', sans-serif !important
    font-weight: 500
    font-size: 3.125rem
    line-height: 3.125rem
    color: #fff

  &.title-2
    font-family: 'RF Dewi', sans-serif !important
    font-weight: 500
    font-size: 2.125rem
    line-height: 2.125rem
    color: #fff

  &.title-3
    font-family: 'RF Dewi', sans-serif !important
    font-weight: 500
    font-size: 1.25rem
    line-height: 1.25rem
    color: #fff

  &.title-4
    font-family: 'RF Dewi', sans-serif !important
    font-weight: 300
    font-size: .65rem
    line-height: 18px
    color: #fff

  &.button-1
    font-family: 'RF Dewi', sans-serif !important
    font-weight: 700
    font-size: .875rem
    line-height: 20px
    color: #fff

  &.button-2
    font-family: 'RF Dewi', sans-serif !important
    font-weight: 600
    font-size: .875rem
    line-height: 24px
    color: #fff

  &.button-3
    font-family: 'RF Dewi', sans-serif !important
    font-weight: 600
    font-size: .75rem
    line-height: 20px
    color: #fff