.homepage
  overflow: hidden
  width: 100%

  .container
    margin-left: auto
    margin-right: auto
    padding-left: calc(1.5rem * .5)
    padding-right: calc(1.5rem * .5)
    width: 100%

//.containerFluid
//  margin-left: auto
//  margin-right: auto
//  padding-left: 0
//  padding-right: 0
//  width: 100%
//  box-sizing: border-box

@media (min-width: 300px)
  .container
    max-width: 315px
    padding-left: 30px
    padding-right: 30px

@media (min-width: 1001px)
  .container
    max-width: 960px

@media (min-width: 1201px)
  .container
    max-width: 1160px

@media (min-width: 1341px)
  .container
    max-width: 1252px