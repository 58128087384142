.topProductItem
  display: flex
  gap: 16px
  align-items: center
  position: relative

  .itemProductImg
    display: flex
    position: relative

    a
      display: flex

    img
      width: 50px
      height: 50px
      border-radius: 10px

    .itemProductCountMob
      display: none
      background: rgba(28, 28, 28, 1)
      border-radius: 20px
      width: 30px
      height: 20px
      position: absolute
      left: 1px
      bottom: 1px
      align-items: center
      justify-content: center
      font-size: 10px
      font-style: normal
      font-weight: 700
      line-height: normal

  .topProductInfoBlock
    display: flex
    gap: 16px
    flex-direction: column
    position: relative
    width: 100%

    .topProductInfo
      width: 100%
      display: flex
      justify-content: space-between
      align-items: center

      .topProductName
        display: flex
        position: relative

        span
          font-size: 12px
          font-style: normal
          font-weight: 400
          line-height: normal
          text-transform: uppercase
          color: rgba(255, 255, 255, 0.5)

      .topProductNumber
        display: flex
        gap: 70px

        .itemProductCount
          display: flex
          position: relative
          width: 50px
          justify-content: center

          span
            font-size: 12px
            font-style: normal
            font-weight: 600
            line-height: normal
            color: rgba(255, 255, 255, 0.5)

        .itemProductTotal
          display: flex
          position: relative
          gap: 4px
          width: 100px
          text-align: right
          justify-content: flex-end

          span
            font-size: 12px
            font-style: normal
            font-weight: 400
            line-height: normal
            color: #fff

    .statusTopProduct
      display: flex
      position: relative
      border-radius: 4px
      overflow: hidden

      .statusTopProductLine
        position: absolute
        width: 20%
        height: 100%
        background: #3968FF
        border-radius: 4px
        top: 0
        left: 0

      .statusTopProductLineBg
        width: 100%
        height: 4px
        background: rgba(255, 255, 255, 0.05)
        border-radius: 4px

@media screen and (max-width: 1200px)
  .topProductItem
    .itemProductImg
      .itemProductCountMob
        display: flex

    .topProductInfoBlock
      overflow: hidden

      .topProductInfo
        .topProductNumber
          .itemProductCount
            display: none

          .itemProductTotal
            width: 80px

        .topProductName
          overflow: hidden
          text-overflow: ellipsis
          white-space: nowrap
          width: 100%

          span
            overflow: hidden
            text-overflow: ellipsis
            white-space: nowrap
            width: 100%