.main
  .wrapper
    display: flex
    align-items: center
    justify-content: center
    padding: 110px 0 80px

    .block
      width: 100%
      position: relative
      display: flex
      flex-direction: column
      align-items: center
      gap: 60px

      .title
        text-align: center
        width: 100%

        h1
          font-size: 60px
          font-weight: 700
          line-height: normal
          color: #fff

      .action
        display: flex
        position: relative

      .button
        width: fit-content
        border-radius: 8px
        padding: 15px 36px
        text-decoration: none
        position: relative
        display: flex
        color: #fff
        font-size: 16px
        background: #1B49DC
        z-index: 1
        transition: all .2s ease-out

        &:hover
          background: #0c36b9
          color: #fff
          text-decoration: none
          outline: 0
          opacity: 1

          &::after
            background: linear-gradient(135deg, #2a50c9 15%, #143bbb 100%)

        &:active
          background: #1b49dc
          color: #fff
          text-decoration: none
          outline: 0

        &::after
          content: ""
          background: linear-gradient(135deg, #3961E1 15%, #1B49DC 100%)
          inset: 0
          -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)
          -webkit-mask-composite: xor
          mask-composite: exclude
          padding: 2px
          position: absolute
          width: calc(100% - 4px)
          z-index: 0
          opacity: 1
          border-radius: 8px
          transition: all .2s ease-out

      .mainBox
        width: 100%
        isolation: isolate
        overflow: hidden
        position: absolute
        height: 850px
        z-index: -1
        top: -200px

        &:before
          content: ''
          width: 1000px
          height: 1000px
          flex-shrink: 0
          border-radius: 1000px
          background: radial-gradient(50% 50% at 50% 50%, #1B49DC 50%, #000 50%)
          filter: blur(150px)
          opacity: 0.5
          display: flex
          position: absolute
          top: 0
          right: 0
          left: 0
          bottom: 0
          margin: 0 auto
          z-index: -1

        .box
          .circles
            width: 100%
            height: 100%
            position: absolute
            opacity: .32
            bottom: 0
            z-index: 1

            div
              position: absolute
              border-radius: 50%

              &:before
                background: rgb(255 255 255 / 10%)
                border-radius: inherit
                content: ""
                inset: 0
                -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)
                mask: linear-gradient(#1B49DC 0 0) content-box, linear-gradient(#1B49DC 0 0)
                mask-composite: xor
                -webkit-mask-composite: xor
                mask-composite: exclude
                padding: 1px
                pointer-events: none
                position: absolute

              &:nth-child(1)
                width: 422px
                height: 422px
                left: calc(50% - 211px)
                top: calc(50% - 211px)
                transform: rotate(0)

              &:nth-child(2)
                width: 454px
                height: 454px
                left: calc(50% - 227px)
                top: calc(50% - 227px)
                transform: rotate(-18deg)

              &:nth-child(3)
                width: 486px
                height: 486px
                left: calc(50% - 243px)
                top: calc(50% - 243px)
                transform: rotate(-35deg)

              &:nth-child(4)
                width: 518px
                height: 518px
                left: calc(50% - 259px)
                top: calc(50% - 259px)
                transform: rotate(-52deg)

              &:nth-child(5)
                width: 550px
                height: 550px
                left: calc(50% - 275px)
                top: calc(50% - 275px)
                transform: rotate(-68deg)

              &:nth-child(6)
                width: 582px
                height: 582px
                left: calc(50% - 291px)
                top: calc(50% - 291px)
                transform: rotate(-84deg)

              &:nth-child(7)
                width: 614px
                height: 614px
                left: calc(50% - 307px)
                top: calc(50% - 307px)
                transform: rotate(-99deg)

              &:nth-child(8)
                width: 646px
                height: 646px
                left: calc(50% - 323px)
                top: calc(50% - 323px)
                transform: rotate(-115deg)

              &:nth-child(9)
                width: 678px
                height: 678px
                left: calc(50% - 339px)
                top: calc(50% - 339px)
                transform: rotate(-131deg)

              &:nth-child(10)
                width: 710px
                height: 710px
                left: calc(50% - 355px)
                top: calc(50% - 355px)
                transform: rotate(-146deg)

              &:nth-child(11)
                width: 742px
                height: 742px
                left: calc(50% - 371px)
                top: calc(50% - 371px)
                transform: rotate(-161deg)

              &:nth-child(12)
                width: 774px
                height: 774px
                left: calc(50% - 387px)
                top: calc(50% - 387px)
                transform: rotate(-176deg)

              &:nth-child(13)
                width: 806px
                height: 806px
                left: calc(50% - 403px)
                top: calc(50% - 403px)
                transform: rotate(-191deg)

              &:nth-child(14)
                width: 838px
                height: 838px
                left: calc(50% - 419px)
                top: calc(50% - 419px)
                transform: rotate(-216deg)


            &.gradient
              animation: crafted-shortcuts-circles 10s linear infinite

              div
                opacity: 0.25

                &:before
                  background: conic-gradient(rgba(109, 46, 255, 0) 6.7%, #ffffff 49.99%, rgba(255, 189, 122, 0) 50%)

      .preview
        margin-top: 20px
        display: flex
        position: relative
        box-sizing: border-box
        width: 100%

        .previewWrapper
          width: 100%

          &:after
            content: ''
            width: 100%
            height: 762px
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 50%)
            position: absolute
            display: flex
            top: 130px

          img
            position: relative
            box-sizing: border-box
            width: 100%


    @keyframes crafted-shortcuts-circles
      0%
        opacity: .32
        transform: rotate(0)

      50%
        opacity: 1
        transform: rotate(.5turn)

      100%
        opacity: .32
        transform: rotate(1turn)


@media screen and (max-width: 1200px)
  .main
    .wrapper
      .block
        .preview
          .previewWrapper
            &:after
              top: 50px
              height: 710px

        .mainBox
          &:before
            width: 900px
            height: 900px
            border-radius: 900px
            filter: blur(120px)

@media screen and (max-width: 1000px)
  .main
    .wrapper
      padding: 90px 0 80px

      .block
        gap: 30px

        .preview
          width: initial

          .previewWrapper
            &:after
              top: -18px
              height: 330px
              width: 600px
              left: calc(50% - 300px)
              right: calc(50% - 300px)

        .mainBox
          height: 420px
          top: -90px
          overflow: initial

          .box
            .circles
              width: 100%
              height: 100%
              position: absolute
              opacity: .32
              bottom: 0
              z-index: 1

              div
                position: absolute
                border-radius: 50%

                &:nth-child(1)
                  width: 280px
                  height: 280px
                  left: calc(50% - 140px)
                  top: calc(50% - 140px)
                  transform: rotate(0)

                &:nth-child(2)
                  width: 300px
                  height: 300px
                  left: calc(50% - 150px)
                  top: calc(50% - 150px)
                  transform: rotate(-18deg)

                &:nth-child(3)
                  width: 320px
                  height: 320px
                  left: calc(50% - 160px)
                  top: calc(50% - 160px)
                  transform: rotate(-35deg)

                &:nth-child(4)
                  width: 340px
                  height: 340px
                  left: calc(50% - 170px)
                  top: calc(50% - 170px)
                  transform: rotate(-52deg)

                &:nth-child(5)
                  width: 360px
                  height: 360px
                  left: calc(50% - 180px)
                  top: calc(50% - 180px)
                  transform: rotate(-68deg)

                &:nth-child(6)
                  width: 380px
                  height: 380px
                  left: calc(50% - 190px)
                  top: calc(50% - 190px)
                  transform: rotate(-84deg)

                &:nth-child(7)
                  width: 400px
                  height: 400px
                  left: calc(50% - 200px)
                  top: calc(50% - 200px)
                  transform: rotate(-99deg)

                &:nth-child(8)
                  width: 420px
                  height: 420px
                  left: calc(50% - 210px)
                  top: calc(50% - 210px)
                  transform: rotate(-115deg)

          &:before
            width: 604px
            height: 604px
            border-radius: 604px
            filter: blur(100px)
            left: calc(50% - 302px)
            right: calc(50% - 302px)
            top: -20px

        .title
          h1
            font-size: 22px
            font-weight: 700
            line-height: 37px
            color: #fff