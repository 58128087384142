.modalOverlay
  position: fixed
  inset: 0
  background-color: rgba(0, 0, 0, 0.50)
  z-index: 999
  backdrop-filter: blur(10px)
  transition: all 0.6s ease-out
  opacity: 0

  &.modalVisible
    opacity: 1
    transition: all 0.6s ease-out

  .modalContent
    width: 522px
    height: fit-content
    position: absolute
    top: 0
    right: 0
    left: 0
    bottom: 0
    margin: auto
    overflow: auto
    outline: none
    border-radius: 24px
    padding: 30px
    border: none
    background: linear-gradient(148deg, #101010 7.86%, #111 93.56%)
    box-shadow: 0 0 100px 50px rgba(17, 17, 17, 0.25)
    display: flex
    gap: 30px
    flex-direction: column
    box-sizing: border-box
    transform: translateY(100px)
    transition: all 0.6s ease-out

    &.modalCollection
      width: 780px

      .modalText
        padding: 0 12px 30px 12px

      .collectionList
        display: flex
        align-items: center
        justify-content: center
        gap: 12px
        flex-wrap: wrap
        width: 100%

        .collectionItem
          .collectionItemButton
            align-items: center
            appearance: none
            background: rgba(255, 255, 255, 0.05)
            border-radius: 12px
            border-width: 0
            color: #fff
            cursor: pointer
            display: flex
            gap: 12px
            justify-content: center
            padding: 10px 12px
            text-decoration: none
            white-space: nowrap
            transition: all 0.2s ease-out

            img
              width: 25px
              height: 25px
              border-radius: 25px

            span
              font-size: 12px
              font-style: normal
              font-weight: 400
              line-height: normal
              color: rgba(255, 255, 255, 0.5)
              transition: all 0.2s ease-out

          &:hover
            .collectionItemButton
              background: #3968FF

              span
                color: #fff

          &.active
            .collectionItemButton
              background: #3968FF

              span
                color: #fff

    &.modalVisible
      transform: translateY(0)
      transition: all 0.6s ease-out

    .modalTitle
      display: block
      width: 100%
      position: relative
      box-sizing: border-box

      h2
        font-size: 14px
        font-style: normal
        font-weight: 600
        line-height: normal
        text-transform: uppercase
        box-sizing: border-box

      .modalClose
        display: flex
        position: absolute
        top: 0
        right: 0
        box-sizing: border-box
        cursor: pointer
        opacity: 1
        transition: all 0.2s ease-out

        &:hover
          opacity: 0.5

    .modalText
      display: flex
      width: 100%
      position: relative
      box-sizing: border-box

      p
        font-size: 14px
        font-style: normal
        font-weight: 400
        line-height: 24px
        color: rgba(255, 255, 255, 0.5)
        box-sizing: border-box

    .modalButtons
      display: flex
      width: 100%
      position: relative
      box-sizing: border-box
      gap: 20px

      .buttonCancel
        width: 50%
        position: relative
        border-radius: 8px
        background: rgba(255, 255, 255, 0.05)
        color: #fff
        transition: all 0.2s ease-out
        opacity: 1

        &:hover
          opacity: 0.8

      .buttonSelect
        width: 50%
        position: relative
        border-radius: 8px
        background: #3968FF
        color: #fff
        transition: all 0.2s ease-out
        opacity: 1

        &:hover
          background: #1B49DC

// Иконки
i
  &.iconClose
    content: ''
    border: none
    display: inline-block
    flex-grow: 0
    flex-shrink: 0
    height: 24px
    width: 24px
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'%3E%3Cpath stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='m8 8 8 8m0-8-8 8'/%3E%3C/svg%3E%0A")
    background-position: 50%
    background-repeat: no-repeat
    background-size: 100%
    transition: all 0.2s ease-out

@media screen and (max-width: 1200px)
  .modalOverlay
    .modalContent
      position: relative
      //overflow: inherit !important
      //
      //&:before
      //  content: ''
      //  width: 90px
      //  height: 4px
      //  flex-shrink: 0
      //  border-radius: 10px
      //  background: #FFF
      //  position: absolute
      //  top: -10px
      //  left: 0
      //  right: 0
      //  margin: 0 auto
      //  opacity: 0.2

      &.modalCollection
        width: 100% !important
        border-radius: 0
        height: 100%
        background: rgba(0, 0, 0, 0.50)
        align-items: center
        justify-content: center

        .modalText
          padding: 0 !important

        .collectionList
          .collectionItem
            .collectionItemButton
              padding: 10px

              span
                font-size: 10px !important

              img
                width: 18px !important
                height: 18px !important

        .modalTitle
          position: absolute !important
          top: 20px
          right: 20px
