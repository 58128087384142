.select
  .select__control
    background-color: #080808
    border: none
    box-shadow: none
    border-radius: 10px
    padding: 0
    font-weight: 300
    font-size: 14px
    line-height: 24px
    opacity: 1
    display: flex
    width: 100%
    height: 48px
    position: relative
    z-index: 1
    box-sizing: border-box
    transition: all .2s ease-out
    cursor: pointer

    .select__value-container
      padding: 10px 32px 10px 24px

      .select__single-value
        font-size: 14px
        overflow: hidden
        font-weight: 400
        color: #8E8E8E
        opacity: 1
        margin: 0
        transition: all .2s ease-out

      input
        padding: 0
        width: 0 !important

    .select__indicators
      margin-right: 16px

      .select__indicator-separator
        display: none

      .select__indicator
        width: 24px
        height: 24px
        padding: 4px
        display: flex
        align-items: center
        justify-content: center
        color: #fff
        opacity: 0.5
        transition: all .2s ease-out

  .select__control--is-focused
    .select__value-container
      .select__single-value
        color: #fff

    .select__indicators
      .select__indicator
        opacity: 1
        transform: rotate(180deg)

  .select__menu
    background: #080808
    border: none
    border-radius: 10px
    border-top: 0
    box-shadow: 0px 4px 12px 0px rgb(0 0 0/20%)
    box-sizing: border-box
    overflow: hidden
    left: 0
    margin: 0
    position: absolute
    top: 100%
    width: 100%
    z-index: 10
    padding: 10px
    transition: all .2s ease-out
    margin-top: 10px

    .select__menu-list
      padding: 0
      --scrollbar-foreground: #1a1a1a
      --scrollbar-background: #101010
      --scrollbar-size: 16px
      scrollbar-color: var(--scrollbar-foreground) var(--scrollbar-background)
      display: flex
      flex-direction: column
      gap: 4px

      &::-webkit-scrollbar
        display: none

      &::-webkit-scrollbar-thumb
        border-radius: 10px
        border: 6px solid #101010
        background: var(--scrollbar-foreground)

      &::-webkit-scrollbar-track
        border-radius: 0px
        background: var(--scrollbar-background)


      .select__option
        opacity: 1
        cursor: pointer
        background: #101010
        border-radius: 8px
        color: #fff
        display: block
        font-weight: 300
        font-size: 0.875rem
        line-height: 20px
        height: auto
        padding: 12px
        text-decoration: none
        transition: all .2s ease-out

        &.select__option--is-selected
          background: #1a1a1a !important

        &:hover
          background: #1a1a1a !important