@font-face
  font-family: "RF Dewi"
  src: url('../../../../public/fonts/RFDewi-Thin.eot')
  src: local('RF Dewi')
  src: url('../../../../public/fonts/RFDewi-Thin.eot?#iefix') format('embedded-opentype')
  src: url('../../../../public/fonts/RFDewi-Thin.woff') format('woff')
  src: url('../../../../public/fonts/RFDewi-Thin.ttf') format('truetype')
  font-weight: 200
  font-style: normal

@font-face
  font-family: "RF Dewi"
  src: url('../../../../public/fonts/RFDewi-Ultralight.eot')
  src: local('RF Dewi')
  src: url('../../../../public/fonts/RFDewi-Ultralight.eot?#iefix') format('embedded-opentype')
  src: url('../../../../public/fonts/RFDewi-Ultralight.woff') format('woff')
  src: url('../../../../public/fonts/RFDewi-Ultralight.ttf') format('truetype')
  font-weight: 250
  font-style: normal

@font-face
  font-family: "RF Dewi"
  src: url('../../../../public/fonts/RFDewi-Light.eot')
  src: local('RF Dewi')
  src: url('../../../../public/fonts/RFDewi-Light.eot?#iefix') format('embedded-opentype')
  src: url('../../../../public/fonts/RFDewi-Light.woff') format('woff')
  src: url('../../../../public/fonts/RFDewi-Light.ttf') format('truetype')
  font-weight: 300
  font-style: normal

@font-face
  font-family: "RF Dewi"
  src: url('../../../../public/fonts/RFDewi-Regular.eot')
  src: local('RF Dewi')
  src: url('../../../../public/fonts/RFDewi-Regular.eot?#iefix') format('embedded-opentype')
  src: url('../../../../public/fonts/RFDewi-Regular.woff') format('woff')
  src: url('../../../../public/fonts/RFDewi-Regular.ttf') format('truetype')
  font-weight: 400
  font-style: normal

@font-face
  font-family: "RF Dewi"
  src: url('../../../../public/fonts/RFDewi-Semibold.eot')
  src: local('RF Dewi')
  src: url('../../../../public/fonts/RFDewi-Semibold.eot?#iefix') format('embedded-opentype')
  src: url('../../../../public/fonts/RFDewi-Semibold.woff') format('woff')
  src: url('../../../../public/fonts/RFDewi-Semibold.ttf') format('truetype')
  font-weight: 600
  font-style: normal

@font-face
  font-family: "RF Dewi"
  src: url('../../../../public/fonts/RFDewi-Bold.eot')
  src: local('RF Dewi')
  src: url('../../../../public/fonts/RFDewi-Bold.eot?#iefix') format('embedded-opentype')
  src: url('../../../../public/fonts/RFDewi-Bold.woff') format('woff')
  src: url('../../../../public/fonts/RFDewi-Bold.ttf') format('truetype')
  font-weight: 700
  font-style: normal

@font-face
  font-family: "RF Dewi"
  src: url('../../../../public/fonts/RFDewi-Ultrabold.eot')
  src: local('RF Dewi')
  src: url('../../../../public/fonts/RFDewi-Ultrabold.eot?#iefix') format('embedded-opentype')
  src: url('../../../../public/fonts/RFDewi-Ultrabold.woff') format('woff')
  src: url('../../../../public/fonts/RFDewi-Ultrabold.ttf') format('truetype')
  font-weight: 800
  font-style: normal

@font-face
  font-family: "RF Dewi"
  src: url('../../../../public/fonts/RFDewi-Black.eot')
  src: local('RF Dewi')
  src: url('../../../../public/fonts/RFDewi-Black.eot?#iefix') format('embedded-opentype')
  src: url('../../../../public/fonts/RFDewi-Black.woff') format('woff')
  src: url('../../../../public/fonts/RFDewi-Black.ttf') format('truetype')
  font-weight: 900
  font-style: normal